import { Camera, Me, Microphone } from 'mind-sdk-web';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { createConferenceSDK } from '@/lib/conference-sdk.ts';
import { filterMediaDevices } from '@/lib/filter-media-devices.ts';
import { ScreenEventListener } from '@/event/ScreenEventListener.ts';
import { useDeviceStore } from '@/store/DeviceStore.ts';
import { MicrophoneEventListener } from '@/event/MicrophoneEventListener.ts';
import { CameraEventListener } from '@/event/CameraEventListener.ts';
import { toast } from 'sonner';
import { create } from '@/store/create-disposable-store.ts';
import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { useConferenceStore } from '@/store/ConferenceStore.ts';
import { useSelectedDeviceStore } from '@/store/SelectedDeviceStore.ts';
import { DeviceRegistryEventListener } from '@/event/DeviceRegistryEventListener.ts';

export const getDeviceService = (): DeviceService => useDeviceServiceStore.getState().deviceService;
export const useDeviceService = (): DeviceService => useDeviceServiceStore((state) => state.deviceService);

export interface DeviceService {
  setupMediaDevices(): Promise<void>;
  useMicrophone(microphone: Microphone): Promise<void>;
  toggleMicrophone: () => Promise<void>;
  useCamera(camera: Camera): Promise<void>;
  toggleCamera: () => Promise<void>;
  setupPrimaryMediaStream(me: Me | null): Promise<void>;
  setupSecondaryMediaStream(me: Me | null): Promise<void>;
}

export class DeviceServiceImpl implements DeviceService {
  private readonly _sdk: IConferenceSDK;

  constructor() {
    this._sdk = createConferenceSDK();
  }

  async setupMediaDevices(): Promise<void> {
    const deviceStore = useDeviceStore.getState();
    deviceStore.setAvailableMicrophones(filterMediaDevices(this._sdk.getDeviceRegistry().getMicrophones()));
    deviceStore.setAvailableCameras(filterMediaDevices(this._sdk.getDeviceRegistry().getCameras()));
    this._sdk.getDeviceRegistry().setListener(new DeviceRegistryEventListener(this));

    await this._useDefaultMicrophone();
    await this._useDefaultCamera();
  }

  private async _useDefaultMicrophone(): Promise<void> {
    const deviceStore = useDeviceStore.getState();

    const microphones: Microphone[] = deviceStore.availableMicrophones;
    const prevSelectedMicId = useSelectedDeviceStore.getState().selectedMicrophoneId;
    const mic = microphones.find((mic) => mic.getId() === prevSelectedMicId) || microphones[0];

    if (deviceStore.microphone?.getId() !== mic?.getId()) {
      await this._useMicrophoneImpl(mic);
    }
  }

  private async _useDefaultCamera(): Promise<void> {
    const deviceStore = useDeviceStore.getState();

    const cameras = deviceStore.availableCameras;
    const prevSelectedCamId = useSelectedDeviceStore.getState().selectedCameraId;
    const cam = cameras.find((cam) => cam.getId() === prevSelectedCamId) || cameras[0];

    if (deviceStore.camera?.getId() !== cam?.getId()) {
      await this._useCameraImpl(cam);
    }
  }

  async useMicrophone(microphone: Microphone): Promise<void> {
    await this._useMicrophoneImpl(microphone);
    useSelectedDeviceStore.getState().setMicrophoneId(microphone.getId());
  }

  private async _useMicrophoneImpl(microphone: Microphone): Promise<void> {
    const deviceStoreState = useDeviceStore.getState();

    const prevMic = deviceStoreState.microphone;

    try {
      prevMic && (await prevMic.release());

      if (microphone) {
        if (deviceStoreState.microphoneEnabled) {
          await microphone.acquire();
        }

        microphone.setNoiseSuppression(true);
        microphone.setListener(new MicrophoneEventListener());
      }

      deviceStoreState.setMicrophone(microphone);

      const me = useConferenceStore.getState().me;
      await this.setupPrimaryMediaStream(me);
    } catch (e) {
      toast.error(`Can't switch microphone: ${e.message}`);
    }
  }

  async toggleMicrophone(): Promise<void> {
    const state = useDeviceStore.getState();
    try {
      if (!state.microphone) return;

      state.microphoneEnabled ? await state.microphone?.release() : await state.microphone?.acquire();
      state.microphone?.setMuted(state.microphoneEnabled);
      state.setMicrophoneEnabled(!state.microphoneEnabled);
    } catch (e) {
      toast.error(`Can't ${state.microphoneEnabled ? 'stop' : 'start'} microphone: ${e.message}`);
    }
  }
  async useCamera(camera: Camera): Promise<void> {
    await this._useCameraImpl(camera);
    useSelectedDeviceStore.getState().setCameraId(camera.getId());
  }

  private async _useCameraImpl(camera: Camera): Promise<void> {
    const deviceStoreState = useDeviceStore.getState();
    const prevCamera = deviceStoreState.camera;

    try {
      prevCamera && (await prevCamera.release());

      if (camera) {
        if (deviceStoreState.cameraEnabled) {
          await camera.acquire();
        }

        camera.setListener(new CameraEventListener());
      }

      deviceStoreState.setCamera(camera);

      const me = useConferenceStore.getState().me;
      await this.setupPrimaryMediaStream(me);
    } catch (e) {
      toast.error(`Can't switch camera: ${e.message}`);
    }
  }

  async toggleCamera(): Promise<void> {
    const state = useDeviceStore.getState();

    try {
      if (!state.camera) return;

      state.cameraEnabled ? await state.camera?.release() : await state.camera?.acquire();
      state.setCameraEnabled(!state.cameraEnabled);
    } catch (e) {
      toast.error(`Can't ${state.cameraEnabled ? 'stop' : 'start'} camera: ${e.message}`);
    }
  }

  async setupPrimaryMediaStream(me: Me | null): Promise<void> {
    const microphone = useDeviceStore.getState().microphone;
    const camera = useDeviceStore.getState().camera;

    const primaryMediaStream = microphone || camera ? this._sdk.createMediaStream(microphone, camera) : null;
    useDeviceStore.getState().setPrimaryMediaStream(primaryMediaStream);

    if (me) {
      primaryMediaStream && primaryMediaStream.addAudioConsumer(new AudioStreamListener(me.getId()));
      me.setMediaStream(primaryMediaStream);
    }
  }

  async setupSecondaryMediaStream(me: Me | null): Promise<void> {
    const deviceRegistry = this._sdk.getDeviceRegistry();
    const screen = deviceRegistry.getScreen();
    if (screen) {
      screen.setListener(new ScreenEventListener());
      useDeviceStore.getState().setScreen(screen);
    }

    const secondaryMediaStream = this._sdk.createMediaStream(screen, screen);
    useDeviceStore.getState().setSecondaryMediaStream(secondaryMediaStream);
    if (secondaryMediaStream && me) {
      me.setSecondaryMediaStream(secondaryMediaStream);
    }
  }
}

const useDeviceServiceStore = create(() => ({
  deviceService: new DeviceServiceImpl(),
}));
