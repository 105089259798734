import { ConferenceNotFoundError } from '@/error/ConferenceNotFoundError';
import { SessionEventListener } from '@/event/SessionEventListener';
import { useConferenceStore } from '@/store/ConferenceStore';
import { useDeviceStore } from '@/store/DeviceStore';
import { Participant as MindParticipant, Session } from 'mind-sdk-web';
import { create, resetAllStores } from '@/store/create-disposable-store.ts';
import { createConferenceSDK } from '@/lib/conference-sdk.ts';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { useUIStore } from '@/store/UIStore.ts';
import { getDeviceService } from '@/service/DeviceService.ts';
import { Participant } from '@/sdk/Participant.ts';
import { ConferenceHistoryRecord } from '@/sdk/ConferenceHistoryRecord.ts';
import { AuthService, getAuthService } from '@/service/AuthService.ts';
import { toast } from 'sonner';

export const getConferenceService = (): ConferenceService => useConferenceServiceStore.getState().conferenceService;
export const useConferenceService = (): ConferenceService =>
  useConferenceServiceStore((state) => state.conferenceService);

export interface ConferenceService {
  createConferenceRoom(): Promise<string | null>;
  createParticipant(roomId: string): Promise<Participant>;
  createAnonymousParticipant(roomId: string, name: string): Promise<Participant>;
  join(participant: Participant): Promise<void>;
  closeSession(): void;
  isParticipantMe(participantId: string): boolean;
  toggleRecording(): Promise<void>;
  togglePresenting(): Promise<void>;
  approveJoinRequest(joinRequestId: string): Promise<void>;
  rejectJoinRequest(joinRequestId: string): Promise<void>;
  getConferencesHistory(): Promise<ConferenceHistoryRecord[]>;
}

class ConferenceServiceImpl implements ConferenceService {
  private _sdk: IConferenceSDK;
  private _authService: AuthService;

  constructor() {
    this._sdk = createConferenceSDK();
    this._authService = getAuthService();
  }

  async createConferenceRoom(): Promise<string | null> {
    return await this._sdk.createConferenceRoom();
  }

  async createParticipant(roomId: string): Promise<Participant> {
    const participant = await this._sdk.createParticipant(roomId);
    useConferenceStore.getState().setMindToken(participant.token);
    return participant;
  }

  async createAnonymousParticipant(roomId: string, name: string): Promise<Participant> {
    const anonymousUser = this._authService.getAnonymousUser();
    let anonymousAuthToken = anonymousUser?.token;

    if (
      !anonymousAuthToken ||
      anonymousUser?.roomId !== roomId ||
      anonymousUser?.name !== name ||
      anonymousUser?.exp * 1000 < Date.now()
    ) {
      anonymousAuthToken = await this._sdk.createAnonymousAuthToken(roomId, name);
      this._authService.saveAnonymousToken(anonymousAuthToken);
    }

    const participant = await this._sdk.createAnonymousParticipant(anonymousAuthToken);
    useConferenceStore.getState().setMindToken(participant.token);

    return participant;
  }

  async join(participant: Participant): Promise<void> {
    const session = await this.initializeSession(participant);
    if (!session) {
      throw new ConferenceNotFoundError();
    }

    session.setListener(new SessionEventListener());

    const mindConference = session.getConference();

    const me = mindConference.getMe();
    useConferenceStore.getState().setMe(me);

    await getDeviceService().setupPrimaryMediaStream(me);
    await getDeviceService().setupSecondaryMediaStream(me);

    const participants = session.getConference()?.getParticipants() || [];
    participants?.forEach((participant) =>
      participant.getMediaStream().addAudioConsumer(new AudioStreamListener(participant.getId()))
    );

    // Recording
    useUIStore.getState().setRecordingEnabled(mindConference.isRecording());

    // Screen presenting
    const presentingParticipant = participants.find((p) => p.isStreamingSecondaryVideo());
    if (presentingParticipant) {
      useUIStore.getState().setPresentingParticipant(presentingParticipant);
    }

    const conferenceStore = useConferenceStore.getState();
    conferenceStore.setConference(participant.conference);
    conferenceStore.setSession(session);
    conferenceStore.addParticipants([me, ...participants]);

    const isAuthorizedUser = await this._authService.isAuthorized();
    if (isAuthorizedUser) {
      const messages = await this._sdk.getRoomChatMessages(participant.conference.roomId);
      useUIStore.getState().addMessages(messages);
    }
  }

  private async initializeSession(participant: Participant): Promise<Session> {
    if (!participant || !participant.token) {
      throw new Error('Participant or token is not defined');
    }

    const baseUrl = import.meta.env.VITE_APP_BASE_URL;
    const mindAppId = participant.appId;
    const conferenceId = participant.conference.id;
    const conferenceUrl = `${baseUrl}/${mindAppId}/${conferenceId}`;
    return await this._sdk.joinToConference(conferenceUrl, participant.token);
  }

  closeSession(): void {
    const session = useConferenceStore.getState().session;
    if (session) {
      this._sdk.closeSession(session);
    }

    const deviceStore = useDeviceStore.getState();
    deviceStore.microphone?.destroy();
    deviceStore.camera?.destroy();
    deviceStore.screen?.release();

    resetAllStores();
  }

  isParticipantMe(participantId: string): boolean {
    const session = useConferenceStore.getState().session;
    const me = session?.getConference()?.getMe();
    return me?.getId() === participantId;
  }

  async toggleRecording(): Promise<void> {
    const conference = useConferenceStore.getState().conference;
    const session = useConferenceStore.getState().session;
    const recordingEnabled = useUIStore.getState().recordingEnabled;
    if (!conference || !session) {
      return;
    }

    try {
      if (recordingEnabled) {
        await this._sdk.stopRecording(conference.roomId);
        session?.fireOnConferenceRecordingStopped(session.getConference());
      } else {
        await this._sdk.startRecording(conference.roomId);
        session?.fireOnConferenceRecordingStarted(session.getConference());
      }
    } catch (e) {
      toast.error(`Can't ${recordingEnabled ? 'stop' : 'start'} recording: ${e.message}`);
    }
  }

  async togglePresenting(): Promise<void> {
    const { screenPresentingEnabled, screen, setScreenPresentingEnabled } = useDeviceStore.getState();
    const { unsetPresentingParticipant, setPresentingParticipant } = useUIStore.getState();
    const me: MindParticipant | undefined = useConferenceStore.getState().session?.getConference()?.getMe();

    try {
      if (!screen) return;

      if (screenPresentingEnabled) {
        await screen.release();
        unsetPresentingParticipant();
      } else {
        await screen.acquire();
        if (me) setPresentingParticipant(me);
      }

      setScreenPresentingEnabled(!screenPresentingEnabled);
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        console.log('User denied screen presenting.');
      } else {
        toast.error(`Can't ${screenPresentingEnabled ? 'stop' : 'start'} screen presenting: ${error.message}`);
      }
    }
  }

  async approveJoinRequest(joinRequestId: string): Promise<void> {
    try {
      await this._sdk.approveJoinRequest(joinRequestId);
    } catch (e) {
      toast.error(`Can't approve join request: ${e.message}`);
    }
  }

  async rejectJoinRequest(joinRequestId: string): Promise<void> {
    try {
      await this._sdk.rejectJoinRequest(joinRequestId);
    } catch (e) {
      toast.error(`Can't reject join request: ${e.message}`);
    }
  }

  async getConferencesHistory(): Promise<ConferenceHistoryRecord[]> {
    return await this._sdk.getConferencesHistory();
  }
}

const useConferenceServiceStore = create(() => ({
  conferenceService: new ConferenceServiceImpl(),
}));
