import { Camera, MediaStream, Microphone, Screen } from 'mind-sdk-web';
import { create } from '@/store/create-disposable-store.ts';

export interface DeviceStore {
  microphoneEnabled: boolean;
  setMicrophoneEnabled: (enabled: boolean) => void;
  microphone: Microphone | null;
  setMicrophone: (microphone: Microphone | null) => void;
  availableMicrophones: Microphone[];
  setAvailableMicrophones: (microphones: Microphone[]) => void;

  cameraEnabled: boolean;
  setCameraEnabled: (enabled: boolean) => void;

  camera: Camera | null;
  setCamera: (camera: Camera | null) => void;
  availableCameras: Camera[];
  setAvailableCameras: (cameras: Camera[]) => void;

  screenPresentingEnabled: boolean;
  setScreenPresentingEnabled: (enabled: boolean) => void;

  screen: Screen | null;
  setScreen: (screen: Screen | null) => void;

  primaryMediaStream: MediaStream | null;
  setPrimaryMediaStream: (mediaStream: MediaStream | null) => void;

  secondaryMediaStream: MediaStream | null;
  setSecondaryMediaStream: (mediaStream: MediaStream | null) => void;
}

export const useDeviceStore = create<DeviceStore>((set) => ({
  microphoneEnabled: true,
  microphone: null,
  setMicrophone: (microphone: Microphone | null) => set({ microphone }),
  setMicrophoneEnabled: (enabled: boolean) => {
    set({ microphoneEnabled: enabled });
  },
  availableMicrophones: [],
  setAvailableMicrophones: (microphones: Microphone[]) => set({ availableMicrophones: microphones.slice() }),

  cameraEnabled: true,
  camera: null,
  setCamera: (camera: Camera | null) => set({ camera }),
  setCameraEnabled: (enabled: boolean) => {
    set({ cameraEnabled: enabled });
  },
  availableCameras: [],
  setAvailableCameras: (cameras: Camera[]) => set({ availableCameras: cameras.slice() }),

  screen: null,
  setScreen: async (screen: Screen | null) => set({ screen: screen }),

  screenPresentingEnabled: false,
  setScreenPresentingEnabled: (enabled: boolean) => set({ screenPresentingEnabled: enabled }),

  primaryMediaStream: null,
  setPrimaryMediaStream: (mediaStream: MediaStream | null) => set({ primaryMediaStream: mediaStream }),

  secondaryMediaStream: null,
  setSecondaryMediaStream: (mediaStream: MediaStream | null) => set({ secondaryMediaStream: mediaStream }),
}));
