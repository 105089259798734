import { create } from '@/store/create-disposable-store.ts';
import { Participant } from 'mind-sdk-web';
import { ChatMessage } from '@/sdk/ChatMessage.ts';

export enum SidePanelType {
  PARTICIPANTS,
  CHAT,
  SETTINGS,
}

interface UIStore {
  activeSidePanel: SidePanelType | null;
  setActiveSidePanel: (panel: SidePanelType | null) => void;

  pendingOperationMessage: string | null;
  setPendingOperationMessage: (message: string | null) => void;

  hasUnreadMessage: boolean;
  setHasUnreadMessage: (unread: boolean) => void;

  presentingParticipant: Participant | null;
  setPresentingParticipant: (participant: Participant) => void;
  unsetPresentingParticipant: () => void;

  messages: ChatMessage[];
  addMessage: (message: ChatMessage) => void;
  addMessages: (messages: ChatMessage[]) => void;

  recordingEnabled: boolean;
  setRecordingEnabled: (recordingEnabled: boolean) => void;
}

export const useUIStore = create<UIStore>((set, get) => ({
  activeSidePanel: null,
  setActiveSidePanel: (panel: SidePanelType | null) => {
    if (panel === SidePanelType.CHAT) {
      set({ activeSidePanel: panel, hasUnreadMessage: false });
    } else {
      set({ activeSidePanel: panel });
    }
  },

  pendingOperationMessage: null,
  setPendingOperationMessage: (message: string | null) => set({ pendingOperationMessage: message }),

  hasUnreadMessage: false,
  setHasUnreadMessage: (unread: boolean) => {
    const isChatPanelActive = get().activeSidePanel === SidePanelType.CHAT;
    set({ hasUnreadMessage: unread && !isChatPanelActive });
  },

  presentingParticipant: null,
  setPresentingParticipant: (participant: Participant) => set(() => ({ presentingParticipant: participant })),
  unsetPresentingParticipant: () => set(() => ({ presentingParticipant: null })),

  messages: [],
  addMessage: (message: ChatMessage) => set((state) => ({ messages: [...state.messages, message] })),
  addMessages: (messages: ChatMessage[]) => set((state) => ({ messages: [...state.messages, ...messages] })),

  recordingEnabled: false,
  setRecordingEnabled: (recordingEnabled: boolean) => set({ recordingEnabled }),
}));
