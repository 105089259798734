import { Clock } from '@/components/Clock';
import { useUser } from '@/hook/useUser.ts';
import UserDropdownMenu from '@/components/UserDropdownMenu.tsx';
import { LoadingSpinner } from './ui/spinner';
import SignInButton from '@/components/button/SignInButton.tsx';

const Header = () => {
  const { user, loading: userLoading } = useUser();

  return (
    <header className="sticky top-0 flex h-[4rem] w-full items-center border-b bg-background p-2 shadow-lg z-10">
      <nav className="flex flex-grow text-lg font-medium">
        <a href="#" className="flex items-center gap-2 font-semibold" onClick={() => (window.location.href = '/')}>
          <img src="/logo-no-text@2x.webp" className="fill-black" alt="IMind" width={20} height={20} />
          IMind Meeting
        </a>
      </nav>
      <div className="flex items-center gap-2">
        <Clock className="hidden sm:flex text-muted-foreground" format="HH:mm [|] dddd, MMMM DD" />
        <div className="min-w-[3rem] flex justify-center items-center">
          {userLoading && <LoadingSpinner size={48} className="text-violet-700 stroke-1" />}
          {!userLoading && (user ? <UserDropdownMenu user={user} /> : <SignInButton />)}
        </div>
      </div>
    </header>
  );
};

export default Header;
