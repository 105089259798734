import { useCallback, useState } from 'react';
import { FaCalendarAlt, FaClock, FaPlay } from 'react-icons/fa';
import { DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu.tsx';
import { toast } from 'sonner';
import { useTransientNavigate } from '@/hook/useTransientNavigate.ts';
import { ConferencePageLocationState } from '@/pages/conference/ConferencePage.tsx';
import { useConferenceService } from '@/service/ConferenceService.ts';
import ConferenceLinkDialog from '@/components/ConferenceLinkDialog.tsx';
import { createEventInGoogleCalendar } from '@/lib/calendar.ts';
import { useAuthService } from '@/service/AuthService.ts';

const CreateConferenceMenu = () => {
  const { transientNavigate } = useTransientNavigate<ConferencePageLocationState>();
  const conferenceService = useConferenceService();
  const authService = useAuthService();

  const [isConferenceLinkDialogOpen, setIsConferenceLinkDialogOpen] = useState(false);
  const [conferenceLink, setConferenceRoomUrl] = useState<string | null>(null);

  const createConferenceRoom = useCallback(async (): Promise<string | null> => {
    try {
      if (!(await authService.isAuthorized())) {
        authService.login();
        return null;
      }

      return await conferenceService.createConferenceRoom();
    } catch (e) {
      console.error(e);
      toast.error(`An error occurred while creating conference room`);
    }

    return null;
  }, [conferenceService, authService]);

  const handleCreateConferenceForLater = useCallback(async () => {
    const roomId = await createConferenceRoom();
    if (roomId) {
      setConferenceRoomUrl(`${import.meta.env.VITE_APP_BASE_URL}/${roomId}`);
      setIsConferenceLinkDialogOpen(true);
    }
  }, [createConferenceRoom]);

  const handleCreateInstantConference = useCallback(async () => {
    const roomId = await createConferenceRoom();
    if (roomId) {
      transientNavigate(`/${roomId}`, { skipLobby: true });
    }
  }, [createConferenceRoom, transientNavigate]);

  const handleCreateConferenceInGoogleCalendar = useCallback(async () => {
    const roomId = await createConferenceRoom();
    if (roomId) {
      createEventInGoogleCalendar(`${import.meta.env.VITE_APP_BASE_URL}/${roomId}`);
    }
  }, [createConferenceRoom]);

  return (
    <>
      <DropdownMenuContent align="start">
        <DropdownMenuItem className="text-base p-2 text-muted-foreground" onClick={handleCreateInstantConference}>
          <FaPlay className="inline w-3 h-3 mr-3" />
          Start an instant conference
        </DropdownMenuItem>
        <DropdownMenuItem className="text-base p-2 text-muted-foreground" onClick={handleCreateConferenceForLater}>
          <FaClock className="inline w-3 h-3 mr-3" />
          Create a conference for later
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-base p-2 text-muted-foreground"
          onClick={handleCreateConferenceInGoogleCalendar}
        >
          <FaCalendarAlt className="inline w-3 h-3  mr-3" />
          Schedule in Google Calendar
        </DropdownMenuItem>
      </DropdownMenuContent>

      <ConferenceLinkDialog
        open={isConferenceLinkDialogOpen}
        onOpenChange={setIsConferenceLinkDialogOpen}
        link={conferenceLink}
      />
    </>
  );
};

export default CreateConferenceMenu;
