// Define a generic type T which can be either Microphone or Camera
import { Microphone, Camera } from 'mind-sdk-web';

export function filterMediaDevices<T extends Microphone | Camera>(devices: T[]): T[] {
  const unnecessaryIds = ['communications', 'default'];

  // 1. Filter out devices with invalid deviceId
  let filteredDevices = devices.filter((device) => !!device.getId());

  // 2. Filter out devices that have IDs in the unnecessary list
  // NOTE: Sometimes the browser might leave a single 'default' device,
  // for example, to prompt for permission to access the camera/microphone.
  // In such cases, it's important not to remove the default device.
  // TODO Possibly we should do something that looks like more the way it's done in google meet:
  // remove default devices if there are other devices available with the same 'groupId'. But the 'groupId'
  // property is not available in mind's interfaces.
  if (filteredDevices.length > 1) {
    filteredDevices = filteredDevices.filter((device) => !unnecessaryIds.includes(device.getId()));
  }

  return filteredDevices;
}

export function fixDeviceDisplayName(name: string): string {
  // replace device id in the format (xxxx:xxxx) at the end of the name
  return name.replace(/\([0-9a-f]{4}:[0-9a-f]{4}\)$/, '');
}
