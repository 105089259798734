import { nanoid } from 'nanoid';
import axios from 'axios';
import qs from 'qs';
import { AnonymousUser, User } from '@/model/User.ts';
import * as jose from 'jose';
import { create } from '@/store/create-disposable-store.ts';

export const getAuthService = (): AuthService => useAuthServiceStore.getState().authService;
export const useAuthService = (): AuthService => useAuthServiceStore((state) => state.authService);

export interface AuthService {
  authorize(authorizationCode: string, state: string, authReqRedirectUri: string): Promise<void>;
  isAuthorized(): Promise<boolean>;
  refresh(): Promise<void>;
  getUser(): Promise<User | null>;
  getAnonymousUser(): AnonymousUser | null;
  login(): void;
  logout(): void;
  getAccessToken(): string | null;
  getAccessTokenExpiresIn(): number | null;
  getAnonymousToken(): string | null;
  saveAnonymousToken(token: string): void;
}

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const ID_TOKEN_KEY = 'id_token';
const REDIRECT_AFTER_AUTH_URI_KEY = 'redirect_after_auth_uri';

const ANONYMOUS_TOKEN_KEY = 'anonymous_token';

export interface JwtPayload extends jose.JWTPayload {
  email: string;
  given_name: string;
  family_name: string;
}

export interface JwtAnonymousPayload extends JwtPayload {
  room_id: string;
  exp: number;
}

export type AuthTokens = {
  access_token: string;
  refresh_token: string;
  id_token: string;
};

export class AuthServiceImpl implements AuthService {
  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  getAnonymousToken(): string | null {
    return localStorage.getItem(ANONYMOUS_TOKEN_KEY);
  }

  saveAnonymousToken(token: string): void {
    localStorage.setItem(ANONYMOUS_TOKEN_KEY, token);
  }

  private _getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  private _getIdToken(): string | null {
    return localStorage.getItem(ID_TOKEN_KEY);
  }

  async getUser(): Promise<User | null> {
    const expIn: number | null = this.getAccessTokenExpiresIn();
    if (!!expIn && expIn < 0) {
      await this.refresh();
    }

    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }

    return this._decodeAccessToken(accessToken);
  }

  getAnonymousUser(): AnonymousUser | null {
    const anonymousToken = this.getAnonymousToken();
    if (!anonymousToken) {
      return null;
    }

    return this._decodeAnonymousToken(anonymousToken);
  }

  async authorize(authorizationCode: string, state: string, redirectUri: string): Promise<void> {
    const formData = qs.stringify({
      client_id: `${import.meta.env.VITE_OAUTH_CLIENT_ID}`,
      grant_type: 'authorization_code',
      code: authorizationCode,
      state: state,
      redirect_uri: redirectUri,
    });

    const url = import.meta.env.VITE_OAUTH_PROVIDER_URL + '/protocol/openid-connect/token';
    const { data } = await axios.post(url, formData);
    if (!data.access_token) {
      throw new Error('No access token found in the response');
    }

    this._saveAuthTokens(data);
    const localRedirectAfterAuth = localStorage.getItem(REDIRECT_AFTER_AUTH_URI_KEY);
    if (localRedirectAfterAuth) {
      location.href = localRedirectAfterAuth;
      localStorage.removeItem(REDIRECT_AFTER_AUTH_URI_KEY);
    }
  }

  async isAuthorized(): Promise<boolean> {
    const user = await this.getUser();
    return !!user;
  }

  async refresh(): Promise<void> {
    try {
      const refreshToken = this._getRefreshToken();
      if (!refreshToken) {
        throw new Error('No refresh token found');
      }

      const formData = qs.stringify({
        client_id: `${import.meta.env.VITE_OAUTH_CLIENT_ID}`,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      });

      const url = import.meta.env.VITE_OAUTH_PROVIDER_URL + '/protocol/openid-connect/token';
      const { data } = await axios.post(url, formData);
      if (!data.access_token) {
        throw new Error('No access token found in the response');
      }

      this._saveAuthTokens(data);
    } catch (e) {
      console.error('Error refreshing the access token', e.message);
      this._clearAuthTokens();
    }
  }

  private async _decodeAccessToken(accessToken: string): Promise<User | null> {
    if (!accessToken) {
      return null;
    }

    let payload: JwtPayload;
    try {
      const JWKS = jose.createRemoteJWKSet(
        new URL(import.meta.env.VITE_OAUTH_PROVIDER_URL + '/protocol/openid-connect/certs'),
        { cacheMaxAge: 600000 }
      );

      const { payload: josePayload } = await jose.jwtVerify(accessToken, JWKS, {
        algorithms: ['RS256'],
        issuer: import.meta.env.VITE_OAUTH_PROVIDER_URL,
      });

      payload = josePayload as JwtPayload;
    } catch (e) {
      console.error('Error decoding the access token. Clearing the tokens...', e.message);
      this._clearAuthTokens();
      return null;
    }

    if (!payload?.sub || !payload?.email) {
      console.error('No sub or email found in the payload');
      return null;
    }

    return new User(payload.sub, payload.email, payload.given_name, payload.family_name);
  }

  private _decodeAnonymousToken(anonymousToken: string): AnonymousUser | null {
    if (!anonymousToken) {
      return null;
    }

    let payload: JwtAnonymousPayload;
    try {
      payload = jose.decodeJwt<JwtAnonymousPayload>(anonymousToken);
    } catch (e) {
      console.error('Error decoding the anonymous token.', e.message);
      return null;
    }

    if (!payload?.sub || !payload?.room_id || !payload?.exp) {
      console.error('No sub, room_id or exp found in the payload');
      return null;
    }

    return new AnonymousUser(payload.sub, payload.given_name, payload.room_id, payload.exp, anonymousToken);
  }

  login(): void {
    localStorage.setItem(REDIRECT_AFTER_AUTH_URI_KEY, location.pathname + location.search);

    location.href =
      import.meta.env.VITE_OAUTH_PROVIDER_URL +
      '/protocol/openid-connect/auth' +
      `?client_id=${import.meta.env.VITE_OAUTH_CLIENT_ID}` +
      '&scope=openid' +
      '&response_type=code' +
      `&state=${nanoid()}` +
      `&redirect_uri=${encodeURIComponent(import.meta.env.VITE_APP_BASE_URL + `/auth`)}`;
  }

  logout(): void {
    const idToken = this._getIdToken();
    this._clearAuthTokens();
    if (idToken) {
      location.href =
        import.meta.env.VITE_OAUTH_PROVIDER_URL +
        '/protocol/openid-connect/logout' +
        `?id_token_hint=${idToken}` +
        `&post_logout_redirect_uri=${import.meta.env.VITE_APP_BASE_URL}`;
    }
  }

  getAccessTokenExpiresIn(): number | null {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }

    const payload = jose.decodeJwt(accessToken) as JwtPayload;
    if (!payload.exp) {
      return null;
    }

    return payload.exp * 1000 - Date.now();
  }

  private _saveAuthTokens(tokens: AuthTokens) {
    localStorage.setItem(ACCESS_TOKEN_KEY, tokens.access_token);
    localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh_token);
    localStorage.setItem(ID_TOKEN_KEY, tokens.id_token);
  }

  private _clearAuthTokens() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(ANONYMOUS_TOKEN_KEY);
  }
}
const useAuthServiceStore = create(() => ({
  authService: new AuthServiceImpl(),
}));
